const messages = {
  es: {
    translations: {
      signup: {
        title: "Registrarse",
        toasts: {
          success: "�Usuario creado exitosamente! �Inicie sesi�n!",
          fail: "Error al crear usuario. Verifique los datos ingresados.",
        },
        form: {
          name: "Nombre",
          email: "Correo electr�nico",
          password: "Contrase�a",
        },
        buttons: {
          submit: "Registrarse",
          login: "�Ya tienes una cuenta? �Inicia sesi�n!",
        },
      },
      login: {
        title: "Iniciar sesi�n",
        form: {
          email: "Correo electr�nico",
          password: "Contrase�a",
        },
        buttons: {
          submit: "Iniciar sesi�n",
          register: " �Crea tu cuenta en solo unos clics!",
        },
      },
      plans: {
        form: {
          name: "Nombre",
          users: "Usuarios",
          connections: "Conexiones",
          campaigns: "Campa�as",
          schedules: "Programaciones",
          enabled: "Habilitadas",
          disabled: "Deshabilitadas",
          clear: "Cancelar",
          delete: "Eliminar",
          save: "Guardar",
          yes: "S�",
          no: "No",
          money: "$",
        },
      },
      companies: {
        title: "Registrar Empresa",
        form: {
          name: "Nombre de la Empresa",
          plan: "Plan",
          token: "Token",
          submit: "Registrar",
          success: "�Empresa creada exitosamente!",
        },
      },
      auth: {
        toasts: {
          success: "�Inicio de sesi�n exitoso!",
        },
        token: "Token",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Atenciones de hoy: ",
          },
        },
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted: "�Conexi�n con WhatsApp eliminada exitosamente!",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "�Est�s seguro? Esta acci�n no se puede deshacer.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "�Est�s seguro? Deber�s volver a leer el c�digo QR.",
        },
        buttons: {
          add: "Agregar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Intentar de nuevo",
          qrcode: "C�DIGO QR",
          newQr: "Nuevo C�DIGO QR",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Error al iniciar sesi�n en WhatsApp",
            content:
              "Aseg�rate de que tu tel�fono est� conectado a internet e int�ntalo nuevamente, o solicita un nuevo c�digo QR",
          },
          qrcode: {
            title: "Esperando lectura del c�digo QR",
            content:
              "Haz clic en el bot�n 'C�DIGO QR' y lee el c�digo QR con tu tel�fono para iniciar sesi�n",
          },
          connected: {
            title: "�Conexi�n establecida!",
          },
          timeout: {
            title: "Se perdi� la conexi�n con el tel�fono",
            content:
              "Aseg�rate de que tu tel�fono est� conectado a internet y WhatsApp est� abierto, o haz clic en el bot�n 'Desconectar' para obtener un nuevo c�digo QR",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "�ltima actualizaci�n",
          default: "Predeterminado",
          actions: "Acciones",
          session: "Sesi�n",
        },
      },

      whatsappModal: {
        title: {
          add: "Agregar WhatsApp",
          edit: "Editar WhatsApp",
        },
        tabs: {
          general: "General",
          messages: "Mensajes",
          assessments: "Evaluaciones",
          integrations: "Integraciones",
          schedules: "Horario de expediente",
        },
        form: {
          name: "Nombre",
          default: "Predeterminado",
          sendIdQueue: "Fila",
          timeSendQueue: "Redireccionar a cola en X minutos",
          queueRedirection: "Redirecci�n de cola",
          outOfHoursMessage: "Mensaje de fuera de expediente",
          queueRedirectionDesc: "Seleccione una fila para redirigir los contactos que no tienen cola",
          prompt: "Prompt",
          expiresTicket: "Cerrar chats abiertos despu�s de X minutos",
          expiresInactiveMessage: "Mensaje de cierre por inactividad",
          greetingMessage: "Mensaje de saludo",
          complationMessage: "Mensaje de conclusi�n",
          sendIdQueue: "Fila",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado con �xito.",
      },
      qrCode: {
        message: "Lea el c�digo QR para iniciar sesi�n",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "Contacto eliminado con �xito!",
          deletedAll: "Todos los contactos eliminados con �xito!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar ",
          deleteAllTitle: "Eliminar Todos",
          importTitle: "Importar contactos",
          deleteMessage: "�Seguro que desea eliminar este contacto? Se perder�n todos los tickets relacionados.",
          deleteAllMessage: "�Seguro que desea eliminar todos los contactos? Se perder�n todos los tickets relacionados.",
          importMessage: "�Desea importar todos los contactos del tel�fono?",
        },
        buttons: {
          import: "Importar Contactos",
          add: "Agregar Contacto",
          export: "Exportar Contactos",
          delete: "Eliminar Todos Contactos",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo electr�nico",
          actions: "Acciones",
        },
      },
      queueIntegrationModal: {
        title: {
          add: "Agregar proyecto",
          edit: "Editar proyecto",
        },
        form: {
          id: "ID",
          type: "Tipo",
          name: "Nombre",
          projectName: "Nombre del Proyecto",
          language: "Lenguaje",
          jsonContent: "JsonContent",
          urlN8N: "URL",
          typebotSlug: "Typebot - Slug",
          typebotExpires: "Tiempo en minutos para expirar una conversaci�n",
          typebotKeywordFinish: "Palabra para finalizar el ticket",
          typebotKeywordRestart: "Palabra para reiniciar el flujo",
          typebotRestartMessage: "Mensaje al reiniciar la conversaci�n",
          typebotUnknownMessage: "Mensaje de opci�n inv�lida",
          typebotDelayMessage: "Intervalo (ms) entre mensajes",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
          test: "Probar Bot",
        },
        messages: {
          testSuccess: "Integraci�n probada con �xito!",
          addSuccess: "Integraci�n agregada con �xito.",
          editSuccess: "Integraci�n editada con �xito.",
        },
      },
      sideMenu: {
        name: "Men� Lateral Inicial",
        note: "Si est� habilitado, el men� lateral se iniciar� cerrado",
        options: {
          enabled: "Abierto",
          disabled: "Cerrado",
        },
      },
      promptModal: {
        form: {
          name: "Nombre",
          prompt: "Prompt",
          voice: "Voz",
          max_tokens: "M�ximo de Tokens en la respuesta",
          temperature: "Temperatura",
          apikey: "API Key",
          max_messages: "M�ximo de mensajes en el Historial",
          voiceKey: "Clave de la API de Voz",
          voiceRegion: "Regi�n de Voz",
        },
        success: "Prompt guardado con �xito!",
        title: {
          add: "Agregar Prompt",
          edit: "Editar Prompt",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
      },
      prompts: {
        title: "Prompts",
        table: {
          name: "Nombre",
          queue: "Sector/Cola",
          max_tokens: "M�ximo Tokens Respuesta",
          actions: "Acciones",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "�Est� seguro? �Esta acci�n no se puede deshacer!",
        },
        buttons: {
          add: "Agregar Prompt",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Datos del contacto",
          extraInfo: "Informaci�n adicional",
          name: "Nombre",
          number: "N�mero de Whatsapp",
          email: "Email",
          extraName: "Nombre del campo",
          extraValue: "Valor",
          whatsapp: "Conexi�n Origen: "
        },
        buttons: {
          addExtraInfo: "Agregar informaci�n",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado con �xito.",
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
          complationMessage: "Mensaje de conclusi�n",
          outOfHoursMessage: "Mensaje de fuera de horario",
          ratingMessage: "Mensaje de evaluaci�n",
          token: "Token",
          orderQueue: "Orden de la cola (Bot)",
          integrationId: "Integraci�n",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Email",
          password: "Contrase�a",
          profile: "Perfil",
          whatsapp: "Conexi�n Predeterminada",

          allTicket: "Ticket Sin Cola [Invisible]",
          allTicketEnabled: "Habilitado",
          allTicketDesabled: "Deshabilitado",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado con �xito.",
      },
      scheduleModal: {
        title: {
          add: "Nuevo Agendamiento",
          edit: "Editar Agendamiento",
        },
        form: {
          body: "Mensaje",
          contact: "Contacto",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Env�o",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Agendamiento guardado con �xito.",
      },
      tagModal: {
        title: {
          add: "Nueva Etiqueta",
          edit: "Editar Etiqueta",
        },
        form: {
          name: "Nombre",
          color: "Color",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardada con �xito.",
      },
      chat: {
        noTicketMessage: "Seleccione un ticket para comenzar a conversar.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "ARRASTRE Y SUELTE ARCHIVOS EN EL CAMPO ABAJO",
          titleFileList: "Lista de archivo(s)"
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Colas",
      },
      tickets: {
        toasts: {
          deleted: "La atenci�n en la que estaba ha sido eliminada.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Abiertas" },
          closed: { title: "Resueltos" },
          search: { title: "B�squeda" },
        },
        search: {
          placeholder: "Buscar atenci�n y mensajes",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Escribe para buscar usuarios",
        fieldQueueLabel: "Transferir a cola",
        fieldQueuePlaceholder: "Seleccione una cola",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Pendiente",
        assignedHeader: "Atendiendo",
        noTicketsTitle: "�Nada aqu�!",
        noTicketsMessage:
          "No se encontraron tickets con este estado o t�rmino de b�squeda",
        buttons: {
          accept: "Aceptar",
          closed: "Finalizar",
          reopen: "Reabrir"
        },
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar el contacto",
        add: "Agregar",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Tablero",
          connections: "Conexiones",
          tickets: "Tickets",
          quickMessages: "Respuestas R�pidas",
          contacts: "Contactos",
          queues: "Colas y Chatbot",
          tags: "Etiquetas",
          administration: "Administraci�n",
          users: "Usuarios",
          settings: "Configuraciones",
          helps: "Ayuda",
          messagesAPI: "API",
          schedules: "Agendamientos",
          campaigns: "Campa�as",
          annoucements: "Anuncios",
          chats: "Chat Interno",
          financeiro: "Financiero",
          files: "Lista de archivos",
          prompts: "Open.Ai",
          queueIntegration: "Integraciones",
        },
        appBar: {
          notRegister:"Sin notificaciones",
          user: {
            profile: "Perfil",
            logout: "Salir",
          },
        },
      },
      queueIntegration: {
        title: "Integraciones",
        table: {
          id: "ID",
          type: "Tipo",
          name: "Nombre",
          projectName: "Nombre del Proyecto",
          language: "Idioma",
          lastUpdate: "�ltima actualizaci�n",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar Proyecto",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "�Est�s seguro? �Esta acci�n no se puede deshacer! y se eliminar� de las colas y conexiones vinculadas",
        },
      },
      files: {
        title: "Lista de archivos",
        table: {
          name: "Nombre",
          contacts: "Contactos",
          actions: "Acci�n",
        },
        toasts: {
          deleted: "�Lista eliminada correctamente!",
          deletedAll: "�Todas las listas se han eliminado correctamente!",
        },
        buttons: {
          add: "Agregar",
          deleteAll: "Eliminar Todos",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteAllTitle: "Eliminar Todos",
          deleteMessage: "�Est�s seguro de que deseas eliminar esta lista?",
          deleteAllMessage: "�Est�s seguro de que deseas eliminar todas las listas?",
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "N�mero",
          body: "Mensaje",
          token: "Token registrado",
        },
        mediaMessage: {
          number: "N�mero",
          body: "Nombre del archivo",
          media: "Archivo",
          token: "Token registrado",
        },
      },
      notifications: {
        noTickets: "No hay notificaciones.",
      },
      quickMessages: {
        title: "Respuestas R�pidas",
        searchPlaceholder: "Buscar...",
        noAttachment: "Sin adjunto",
        confirmationModal: {
          deleteTitle: "Eliminaci�n",
          deleteMessage: "�Esta acci�n es irreversible! �Quieres continuar?",
        },
        buttons: {
          add: "Agregar",
          attach: "Adjuntar Archivo",
          cancel: "Cancelar",
          edit: "Editar",
        },
        toasts: {
          success: "�Atajo agregado correctamente!",
          deleted: "�Atajo eliminado correctamente!",
        },
        dialog: {
          title: "Mensaje R�pido",
          shortcode: "Atajo",
          message: "Respuesta",
          save: "Guardar",
          cancel: "Cancelar",
          geral: "Permitir editar",
          add: "Agregar",
          edit: "Editar",
          visao: "Permitir vista",
        },
        table: {
          shortcode: "Atajo",
          message: "Mensaje",
          actions: "Acciones",
          mediaName: "Nombre del Archivo",
          status: "Estado",
        },
      },
      messageVariablesPicker: {
        label: "Variables disponibles",
        vars: {
          contactFirstName: "Primer Nombre",
          contactName: "Nombre",
          greeting: "Saludo",
          protocolNumber: "Protocolo",
          date: "Fecha",
          hour: "Hora",
        },
      },
      contactLists: {
        title: "Listas de Contactos",
        table: {
          name: "Nombre",
          contacts: "Contactos",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva Lista",
        },
        dialog: {
          name: "Nombre",
          company: "Empresa",
          okEdit: "Editar",
          okAdd: "Agregar",
          add: "Agregar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Esta acci�n no se puede deshacer.",
        },
        toasts: {
          deleted: "Registro eliminado",
        },
      },
      contactListItems: {
        title: "Contactos",
        searchPlaceholder: "B�squeda",
        buttons: {
          add: "Nuevo",
          lists: "Listas",
          import: "Importar",
        },
        dialog: {
          name: "Nombre",
          number: "N�mero",
          whatsapp: "Whatsapp",
          email: "Correo electr�nico",
          okEdit: "Editar",
          okAdd: "Agregar",
          add: "Agregar",
          edit: "Editar",
          cancel: "Cancelar",
        },
        table: {
          name: "Nombre",
          number: "N�mero",
          whatsapp: "Whatsapp",
          email: "Correo electr�nico",
          actions: "Acciones",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Esta acci�n no se puede deshacer.",
          importMessage: "�Desea importar los contactos de esta hoja de c�lculo?",
          importTitlte: "Importar",
        },
        toasts: {
          deleted: "Registro eliminado",
        },
      },
      campaigns: {
        title: "Campa�as",
        searchPlaceholder: "B�squeda",
        buttons: {
          add: "Nueva Campa�a",
          contactLists: "Listas de Contactos",
        },
        table: {
          name: "Nombre",
          whatsapp: "Conexi�n",
          contactList: "Lista de Contactos",
          status: "Estado",
          scheduledAt: "Programada",
          completedAt: "Completada",
          confirmation: "Confirmaci�n",
          actions: "Acciones",
        },
        dialog: {
          new: "Nueva Campa�a",
          update: "Editar Campa�a",
          readonly: "Solo Lectura",
          form: {
            name: "Nombre",
            message1: "Mensaje 1",
            message2: "Mensaje 2",
            message3: "Mensaje 3",
            message4: "Mensaje 4",
            message5: "Mensaje 5",
            confirmationMessage1: "Mensaje de Confirmaci�n 1",
            confirmationMessage2: "Mensaje de Confirmaci�n 2",
            confirmationMessage3: "Mensaje de Confirmaci�n 3",
            confirmationMessage4: "Mensaje de Confirmaci�n 4",
            confirmationMessage5: "Mensaje de Confirmaci�n 5",
            messagePlaceholder: "Contenido del mensaje",
            whatsapp: "Conexi�n",
            status: "Estado",
            scheduledAt: "Programada",
            confirmation: "Confirmaci�n",
            contactList: "Lista de Contacto",
            tagList: "Lista de Etiquetas",
            fileList: "Lista de Archivos"
          },
          buttons: {
            add: "Agregar",
            edit: "Actualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            restart: "Reiniciar Campa�as",
            close: "Cerrar",
            attach: "Adjuntar Archivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Esta acci�n no se puede deshacer.",
        },
        toasts: {
          success: "Operaci�n realizada correctamente",
          cancel: "Campa�a cancelada",
          restart: "Campa�as reiniciadas",
          deleted: "Registro eliminado",
        },
      },
      announcements: {
        active: 'Activo',
        inactive: 'Inactivo',
        title: "Informativos",
        searchPlaceholder: "B�squeda",
        buttons: {
          add: "Nuevo Informativo",
          contactLists: "Listas de Informativos",
        },
        table: {
          priority: "Prioridad",
          title: "T�tulo",
          text: "Texto",
          mediaName: "Archivo",
          status: "Estado",
          actions: "Acciones",
        },
        dialog: {
          edit: "Editar Informativo",
          add: "Nuevo Informativo",
          update: "Editar Informativo",
          readonly: "Solo Lectura",
          form: {
            priority: "Prioridad",
            title: "T�tulo",
            text: "Texto",
            mediaPath: "Archivo",
            status: "Estado",
          },
          buttons: {
            add: "Agregar",
            edit: "Actualizar",
            okadd: "Ok",
            cancel: "Cancelar",
            close: "Cerrar",
            attach: "Adjuntar Archivo",
          },
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "Esta acci�n no se puede deshacer.",
        },
        toasts: {
          success: "Operaci�n realizada correctamente",
          deleted: "Registro eliminado",
        },
      },
      campaignsConfig: {
        title: "Configuraciones de Campa�as",
      },
      queues: {
        title: "Colas y Chatbot",
        table: {
          id:"ID",
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de bienvenida",
          actions: "Acciones",
          orderQueue: "Orden de la cola (bot)",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "�Est�s seguro? �Esta acci�n no se puede deshacer! Los tickets de esta cola seguir�n existiendo pero ya no tendr�n ninguna cola asignada.",
        },
      },
      queueSelect: {
        inputLabel: "Colas",
      },
      users: {
        title: "Usuarios",
        table: {
          id: "ID",
          name: "Nombre",
          email: "Email",
          profile: "Perfil",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario eliminado correctamente.",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "Todos los datos del usuario se perder�n. Los tickets abiertos de este usuario se mover�n a la cola.",
        },
      },
      helps: {
        title: "Centro de Ayuda",
      },
      schedules: {
        title: "Agendamientos",
        confirmationModal: {
          deleteTitle: "�Est�s seguro que quieres eliminar este Agendamiento?",
          deleteMessage: "Esta acci�n no se puede deshacer.",
        },
        table: {
          contact: "Contacto",
          body: "Mensaje",
          sendAt: "Fecha de Agendamiento",
          sentAt: "Fecha de Env�o",
          status: "Estado",
          actions: "Acciones",
        },
        buttons: {
          add: "Nuevo Agendamiento",
        },
        toasts: {
          deleted: "Agendamiento eliminado correctamente.",
        },
      },
      tags: {
        title: "Etiquetas",
        confirmationModal: {
          deleteTitle: "�Est�s seguro que quieres eliminar esta Etiqueta?",
          deleteMessage: "Esta acci�n no se puede deshacer.",
          deleteAllMessage: "�Est�s seguro que quieres eliminar todas las Etiquetas?",
          deleteAllTitle: "Eliminar Todas",
        },
        table: {
          name: "Nombre",
          color: "Color",
          tickets: "Tickets Etiquetados",
          actions: "Acciones",
        },
        buttons: {
          add: "Nueva Etiqueta",
          deleteAll: "Eliminar Todas",
        },
        toasts: {
          deletedAll: "�Todas las Etiquetas se han eliminado correctamente!",
          deleted: "Etiqueta eliminada correctamente.",
        },
      },
      settings: {
        success: "Configuraciones guardadas correctamente.",
        title: "Configuraciones",
        settings: {
          userCreation: {
            name: "Creaci�n de usuario",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Volver",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Escribe un mensaje",
        placeholderClosed:
          "Reabra o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Datos del contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Informaci�n adicional",
      },
      fileModal: {
        title: {
          add: "Agregar lista de archivos",
          edit: "Editar lista de archivos",
        },
        buttons: {
          okAdd: "Guardar",
          okEdit: "Editar",
          cancel: "Cancelar",
          fileOptions: "Agregar archivo",
        },
        form: {
          name: "Nombre de la lista de archivos",
          message: "Detalles de la lista",
          fileOptions: "Lista de archivos",
          extraName: "Mensaje para enviar con archivo",
          extraValue: "Valor de la opci�n",
        },
        success: "Lista de archivos guardada correctamente!",
      },
      ticketOptionsMenu: {
        schedule: "Programaci�n",
        delete: "Eliminar",
        transfer: "Transferir",
        registerAppointment: "Observaciones del Contacto",
        appointmentsModal: {
          title: "Observaciones del Contacto",
          textarea: "Observaci�n",
          placeholder: "Inserta aqu� la informaci�n que deseas registrar",
        },
        confirmationModal: {
          title: "Eliminar el ticket",
          titleFrom: "del contacto ",
          message:
            "�Atenci�n! Todos los mensajes relacionados con el ticket se perder�n.",
        },
        buttons: {
          delete: "Eliminar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Eliminar",
        reply: "Responder",
        confirmationModal: {
          title: "�Eliminar mensaje?",
          message: "Esta acci�n no se puede deshacer.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Debe haber al menos un WhatsApp predeterminado.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontr� ning�n WhatsApp predeterminado. Comprueba la p�gina de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesi�n de WhatsApp no se ha inicializado. Comprueba la p�gina de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Comprueba la p�gina de conexiones",
        ERR_WAPP_INVALID_CONTACT: "Este no es un n�mero de WhatsApp v�lido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudo descargar medios de WhatsApp. Comprueba la p�gina de conexiones.",
        ERR_INVALID_CREDENTIALS:
          "Error de autenticaci�n. Por favor, int�ntalo de nuevo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar mensaje de WhatsApp. Comprueba la p�gina de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo eliminar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesi�n expirada. Por favor, inicia sesi�n.",
        ERR_USER_CREATION_DISABLED:
          "La creaci�n de usuarios ha sido deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este n�mero.",
        ERR_NO_SETTING_FOUND: "No se encontr� ninguna configuraci�n con esta ID.",
        ERR_NO_CONTACT_FOUND: "No se encontr� ning�n contacto con esta ID.",
        ERR_NO_TICKET_FOUND: "No se encontr� ning�n ticket con esta ID.",
        ERR_NO_USER_FOUND: "No se encontr� ning�n usuario con esta ID.",
        ERR_NO_WAPP_FOUND: "No se encontr� ning�n WhatsApp con esta ID.",
        ERR_CREATING_MESSAGE: "Error al crear mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener mensaje de WhatsApp, puede que sea muy antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya est� en uso, elige otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El saludo es obligatorio cuando hay m�s de una cola.",
      },
    },
  },
};

export { messages };
